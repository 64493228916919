import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import LoadingFallback from './LoadingFallback'

// Better to use lazy() because otherwise all the code is running
// import Test from '../pages/Test'
// import More from '../pages/More'
// import Promises from '../pages/Promises'
// import Use from '../pages/Use'
// const Lazy = lazy(() => import('../pages/Lazy'))

// const pages = ['TestingPage1', 'TestingPage2', 'TestingPage3']
// pages.map(page => {
//   let $page = lazy(() => `../pages/{page}`)
// })

const Test = lazy(() => import('../pages/Test'))
const Lazy = lazy(() => import('../pages/Lazy'))
const More = lazy(() => import('../pages/More'))
const Promises = lazy(() => import('../pages/Promises'))
const Use = lazy(() => import('../pages/Use'))

export default function ReturnRoutes() {
    return (
        <BrowserRouter>
            <Suspense fallback={<LoadingFallback />} >
                <Switch>
                    <Route exact path="/" component={Test} />
                    <Route path="/more" component={More} />
                    <Route path="/lazy" component={Lazy} />
                    <Route path="/fallback" component={LoadingFallback} />
                    <Route path="/promises" component={Promises} />
                    <Route path="/use" component={Use} />
                    {/* {
                        pages.map(page => (
                        <Route path="/{page}" component={{page}} />
                        ))
                    } */}
                </Switch>
            </Suspense>
        </BrowserRouter>
    )
}