import React from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    loadingContainer: {
        width: '100%',
        height: 'calc(100vh - 304px)',
        position: 'relative'
    },
        loader: {
        position: 'absolute',
        top: '50%',
        color: 'green' // change color of loading circle
    }
}));

function LoadingFallback() {
    const classes = useStyles();

    return (
    <Box className={classes.loadingContainer}>
        <CircularProgress className={classes.loader} size={72} />
    </Box>
    );
}

export default LoadingFallback;